import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Button from "@mui/material/Button";
import { pageRoutes } from "../../../routes";
import {
  GaTypography,
  TypographyType,
  TypographyColor,
  TypographyAlignment,
} from "../../ga-components/Typography";
import { GaAccordion } from "../../ga-components/Accordion";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import BrickChemistDetails from "./BrickChemistDetails";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY } from "../../../base";
import { LoggedInUserDetails } from "../../../domain/models/loggedInUserDetails";
import jwt_decode from "jwt-decode";
import { Contacts, Verification } from "../../../domain/models/contacts";
import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Mappings } from "../../../domain/models/mappings";
import Status from "../../../domain/models/status";
import { MappedChemist } from "../../../domain/models/doctor/mappedChemist";

type Props = {
  cardDetails: DoctorData;
};

const DoctorDetailsCard: React.FC<Props> = ({ cardDetails }) => {
  const navigate = useNavigate();
  const [mobileContacts, setMobileContacts] = useState<Contacts[]>([]);
  const [emailContacts, setEmailContacts] = useState<Contacts[]>([]);

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<LoggedInUserDetails>();

  const container = {
    fontFamily: "Roboto",
    width: "100%",
  };
  const subTitleStyle = {
    flex: "grid",
    margin: 2,
    marginBottom: "10px",
    color: "#6B6B6B",
  };

  const isVerified = (verification: Verification | undefined | null) => {
    return verification && verification.status === Status.VERIFIED;
  };

  const verificationStatus = () => {
    const status = {
      name: false,
      mobile: false,
      qualification: false,
      speciality: false,
      addresses: false,
    };

    if (cardDetails.fullNames && cardDetails.fullNames.length > 0) {
      status.name = cardDetails.fullNames.some((name) =>
        isVerified(name.verification)
      );
    }

    if (cardDetails.qualification && cardDetails.qualification.length > 0) {
      status.qualification = cardDetails.qualification.some((qual) =>
        isVerified(qual.verification)
      );
    }

    if (cardDetails.specialities && cardDetails.specialities.length > 0) {
      status.speciality = cardDetails.specialities.some(
        (spec) =>
          cardDetails.speciality === spec.value && isVerified(spec.verification)
      );
    }

    if (cardDetails.addresses && cardDetails.addresses.length > 0) {
      status.addresses = cardDetails.addresses.some(
        (address) => address.isConfirmed
      );
    }

    if (cardDetails.contacts && cardDetails.contacts.length > 0) {
      status.mobile = cardDetails.contacts.some((contact) =>
        isVerified(contact.verification)
      );
    }

    return status;
  };

  const handleClick = () => {
    navigate(pageRoutes.doctorProfilePage, {
      state: {
        id: cardDetails._id,
      },
    });
  };

  const handleTherapyclick = () => {
    navigate(pageRoutes.doctorTherapyPage, {
      state: {
        id: cardDetails._id,
      },
    });
  };
  const handleMapping = () => {
    navigate(pageRoutes.chemistMapping, {
      state: {
        id: cardDetails._id,
        brickCode: checkMapChemistValidation(cardDetails.mappings).brickCode,
        mappedChemist: cardDetails.chemists,
      },
    });
  };

  useEffect(() => {
    const storage = LocalJsonStorage.getInstance();
    const token = storage.get(AUTH_TOKEN_KEY);
    if (token) {
      setLoggedInUserDetails(jwt_decode(token));
    }

    mobileContacts.length = 0;
    {
      cardDetails.contacts?.map((contact, index) => {
        if (contact.type == "mobile" && contact.status == "active") {
          mobileContacts.length = 0;
          mobileContacts.push(contact);
          return <div key={index}></div>;
        } else if (contact.type == "email" && contact.status == "active") {
          emailContacts.length = 0;
          emailContacts.push(contact);
          return <div key={index}></div>;
        }
      });
    }
  }, []);

  const checkMapChemistValidation = (mappingData: Mappings[]) => {
    if (mappingData.length > 0) {
      for (let i = 0; i <= mappingData.length - 1; i++) {
        if (
          mappingData[i].hqCode == loggedInUserDetails?.hq_code &&
          mappingData[i].brickCode &&
          mappingData[i].status === "active"
        ) {
          return mappingData[i];
        }
      }
    }
    return {} as Mappings;
  };

  const filterMappedChemist = () => {
    let filteredData: MappedChemist[] = [];
    if (loggedInUserDetails) {
      filteredData = cardDetails.chemists.filter((item) => {
        return (
          item.deptCode == loggedInUserDetails.dept_code &&
          item.orgCode == loggedInUserDetails.org_code &&
          item.teamCode == loggedInUserDetails.team_code
        );
      });
      return filteredData;
    }
    return filteredData;
  };

  return (
    <div style={container}>
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={handleClick}
      >
        <>
          <div style={{ width: "100%" }}>
            <GaTypography
              typographyType={TypographyType.XL_BOLD}
              color={TypographyColor.INHERIT}
            >
              {cardDetails.fullNames[cardDetails.fullNames.length - 1].name
                .length > 0
                ? cardDetails.fullNames[cardDetails.fullNames.length - 1].name
                : ""}
            </GaTypography>
          </div>
          {checkMapChemistValidation(cardDetails.mappings) &&
            checkMapChemistValidation(cardDetails.mappings).brickName && (
              <GaTypography
                typographyType={TypographyType.SM}
                align={TypographyAlignment.RIGHT}
              >
                {checkMapChemistValidation(cardDetails.mappings).brickName}
              </GaTypography>
            )}
        </>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 2,
          marginBottom: "10px",
        }}
      >
        <GaTypography typographyType={TypographyType.SM}>
          {mobileContacts[mobileContacts.length - 1]?.value}
        </GaTypography>
        <Stack direction={"row"} height={"20px"} spacing={1}>
          {Object.entries(verificationStatus()).map(([key, value]) => {
            return (
              <Stack
                width={"25px"}
                height={"25px"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={value ? "green" : "red"}
                borderRadius={"20px"}
                p={1}
                key={key}
              >
                <Typography color={"white"} fontSize={"12px"}>
                  {key[0].toUpperCase()}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </div>

      <GaAccordion
        expandIcon={<ExpandMore />}
        onChange={() => {}}
        summary={
          <GaTypography
            typographyType={TypographyType.SM}
            color={TypographyColor.INFO}
          >
            {`${filterMappedChemist().length} Mapped Chemist(s) `}
          </GaTypography>
        }
        details={
          <>
            {filterMappedChemist().length > 0 &&
              filterMappedChemist().map((details) => {
                return (
                  <BrickChemistDetails
                    data={details}
                    doctorData={cardDetails}
                  />
                );
              })}
          </>
        }
      />

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Button color="secondary" onClick={handleClick}>
          <Typography variant="subtitle2">Personal</Typography>
        </Button>
        <Stack direction={"row"}>
          {loggedInUserDetails?.dept_code === "GOVAXX" &&
            loggedInUserDetails.org_code == "PFGOVAXX" && (
              <Button color="secondary" onClick={handleTherapyclick}>
                <Typography variant="subtitle2">Therapy</Typography>
                {cardDetails.feedRxStatus &&
                  cardDetails.feedRxStatus.length > 0 &&
                  cardDetails.feedRxStatus.map((rx) => {
                    if (
                      rx.rxFeeded &&
                      rx.deptCode == loggedInUserDetails?.dept_code &&
                      rx.orgCode == loggedInUserDetails.org_code
                    ) {
                      return (
                        <CheckCircleIcon fontSize="small" color="success" />
                      );
                    }
                  })}
              </Button>
            )}
        </Stack>
        {checkMapChemistValidation(cardDetails.mappings) &&
          checkMapChemistValidation(cardDetails.mappings).brickCode && (
            <Button color="secondary" onClick={() => handleMapping()}>
              <Typography variant="subtitle2"> MAP CHEMIST</Typography>
            </Button>
          )}
      </Stack>
    </div>
  );
};

export default DoctorDetailsCard;
